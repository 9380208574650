import { TaasMenuItem } from "utimaco-common-ui-angular";

// see https://netbasal.com/simplifying-file-imports-in-angular-with-new-loader-options-4e94a0eb18af for importing svgs in angular ^17.1
import homeIcon from '@material-icons/svg/svg/home/baseline.svg';
import chartIcon from '@material-icons/svg/svg/insert_chart/baseline.svg';
import connectionIcon from '@material-icons/svg/svg/settings_ethernet/baseline.svg';
import transactionsIcon from '@material-icons/svg/svg/query_stats/baseline.svg';
import helpIcon from '@material-icons/svg/svg/help/baseline.svg';
import quizIcon from '@material-icons/svg/svg/quiz/baseline.svg';

export const TAAS_MENU_ITEM_LIST_TS: TaasMenuItem[] = [
    { id: 0, type: "link", active: true, name: $localize`Home`, link: "/", iconSvg: homeIcon, children: [] },
    {
        id: 1, type: "section", active: false, name: $localize`Reports`, link: "/reports", iconSvg: chartIcon, children: [
            { id: 4, type: "link", active: false, name: $localize`Transactions`, link: "/reports/transactions", iconSvg: transactionsIcon, children: [] },
        ]
    },
    {
        id: 2, type: "section", active: false, name: $localize`Support`, link: "/support", iconSvg: helpIcon, children: [
            { id: 9, type: "link", active: false, name: $localize`Connection Details`, link: "/support/connection-details", iconSvg: connectionIcon, children: [] },
            { id: 10, type: "link", active: false, name: $localize`Documentation`, link: "/support/documentation", iconSvg: quizIcon, children: [] },
        ]
    },   
];
import { ApplicationConfig, importProvidersFrom } from '@angular/core';
import { provideRouter } from '@angular/router';

import { routes } from './app.routes';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { KeycloakAngularModule, KeycloakBearerInterceptor, KeycloakService } from 'keycloak-angular';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER } from '@angular/core';

export const initializeKeycloak = (keycloakService: KeycloakService) => async () => {
  const keycloakInitDone: boolean = await keycloakService.init({
    config: {
      url: 'https://sso.dev.services.utimaco.com',
      realm: 'Utimaco-Portal-DEV',
      clientId: 'TaasServiceManagementFrontend'
    },
    loadUserProfileAtStartUp: true,
    initOptions: {
      onLoad: 'login-required',
      silentCheckSsoRedirectUri:
        window.location.origin + '/assets/silent-check-sso.html',
      checkLoginIframe: false,
    },
  });
  keycloakService.enableBearerInterceptor;

  console.log("Keycloak service initialized: ", keycloakInitDone);
}

export const appConfig: ApplicationConfig = {
  providers: [
    provideRouter(routes),
    provideAnimationsAsync(),
    KeycloakAngularModule,
    KeycloakService,
    KeycloakBearerInterceptor,
    importProvidersFrom(HttpClientModule),
    {
      provide: HTTP_INTERCEPTORS,
      useClass: KeycloakBearerInterceptor,
      multi: true,
      deps: [KeycloakService]
    },
    {
      provide: APP_INITIALIZER,
      useFactory: initializeKeycloak,
      multi: true,
      deps: [KeycloakService],
    },
  ]
};

import { Component } from '@angular/core';
import { Router, NavigationEnd, RouterOutlet } from '@angular/router';

import { TAAS_MENU_ITEM_LIST_TS } from './taas-menuitems-ts';
import { TaasUserService } from './core/services/taas-user-service.service';
import { TaasUserDetails, TaasMenuItem, TaasMainLayoutComponent, TaasHiddenVersionInfoComponent, TaasToastrComponent } from 'utimaco-common-ui-angular';

import { environment } from '../environments/environment';

//NOTE: to be able to import json like this, make sure to add these settings to your ts config:
// "resolveJsonModule": true,
// "allowSyntheticDefaultImports": true,
import appPackageJson from '../../package.json';
import commonUiPackageJson from 'utimaco-common-ui-angular/package.json';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [TaasMainLayoutComponent, RouterOutlet, TaasHiddenVersionInfoComponent, TaasToastrComponent],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss'
})

export class AppComponent {
  constructor(private taasUserService: TaasUserService, private router: Router) {
    router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.activeRoute = event.url;
      }
    });

    taasUserService.getCurrentUserDetails().then(taasUserDetails => {
      this.currentuser = taasUserDetails;
    });
  }

  currentuser: TaasUserDetails = {
    givenName: "",
    familyName: "",
    email: "",
    initials: ""
  };

  activeRoute: string = "/";
  taasMenuItemsTs: TaasMenuItem[] = TAAS_MENU_ITEM_LIST_TS;
  commonUiLibVersion: string = commonUiPackageJson.version;
  appVersion: string = appPackageJson.version;

  handleRouteUpdateRequest(path: string) {
    this.router.navigateByUrl(path);
  }

  navigateHome() {
    window.open(environment.taasPortalFrontendUrl);
  }

  performLogout() {
    this.taasUserService.performLogout();
  }

  handleUserContextMenuEvent(event: string) {
    switch (event) {
      case "logout":
        this.performLogout();
        break;
      case "showOwnProfile":
        console.log("TODO: show own profile");
        break;
      default:
        console.log("Unsupported user info context event received: ", event);
        break;
    }
  }
}

/// <reference types="@angular/localize" />

import { bootstrapApplication } from '@angular/platform-browser';
import { appConfig } from './app/app.config';
import { AppComponent } from './app/app.component';
import { provideToastr } from 'ngx-toastr';
import { provideAnimations } from '@angular/platform-browser/animations';

(async () => {
  const app = await bootstrapApplication(AppComponent, {
    ...appConfig,
    providers: [
      provideAnimations(), // required animations providers
      provideToastr({closeButton: true, tapToDismiss: false}),
            ...(appConfig.providers || []),
    ],
  });
  if(app.isStable){
    console.debug("Application bootstraping complete");
  }
})();




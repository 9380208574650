<main class="main">
  <lib-taas-main-layout titleText="Timestamp as a Service" 
    [userDetails]="currentuser" 
    [menuItemList]="taasMenuItemsTs"
    [activeRoute]="activeRoute" 
    (navigateHomeRequest)="navigateHome()"
    (contextMenuEvent)="handleUserContextMenuEvent($event)" 
    (routeUpdateRequest)="handleRouteUpdateRequest($event)">
    <router-outlet />
  </lib-taas-main-layout>
  <lib-taas-hidden-version-info [appVersion]="appVersion" [commonUiLibVersion]="commonUiLibVersion" />
</main>
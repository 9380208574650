import { Routes } from '@angular/router';
 
export const routes: Routes = [
  { 
    path: '', 
    loadComponent: () => import('./features/home/taas-ts-home.component').then(m => m.TaasTsHomeComponent) 
  },
  { 
    path: 'reports/transactions', 
    loadComponent: () => import('./features/reporting/pages/transactions/taas-ts-reports-transactions.component').then(m => m.TaasTsReportsTransactionsComponent) 
  },
  { 
    path: 'support/connection-details', 
    loadComponent: () => import('./features/support/pages/connection-details/taas-ts-service-connection-details.component').then(m => m.TaasTsServiceConnectionDetailsComponent) 
  },
  { 
    path: 'support/documentation', 
    loadComponent: () => import('./features/support/pages/documentation/taas-ts-documentation.component').then(m => m.DocumentationComponent) 
  }
];